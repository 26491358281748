.casher-footer{
    bottom: 0;
    position: fixed;
    width: 100%;
    left: 0;
    background: #f9f9f9 !important;
    border-top: 1px solid #52c41a;
}

.casher-total-to-pay, .casher-total-to-pay h3 b{
    color: #52c41a !important;
    text-align: right;
}

.ant-layout-sider{
    background: #fff !important;
    padding: 10px !important;
}

.main-box{
    min-height: 100vh;
}

.casher-product-list{
    padding: 0 5px 0 34px !important;
    margin: 0 auto;
    min-height: 50vh !important;
    width: 100% !important;
}

.row-sale-infor > span:hover{
    opacity: 0.8;
}

.sale-infor-side{
    background: "#fff";
    border-bottom: 1px solid #333;
    flex-flow: column
}

@media print {    
    @page {
        margin: 0.5cm !important;
    }
    section{
        flex-direction: column-reverse !important
    }
    .ant-btn-default{
        display: none !important;
    }
    .ant-row{
        margin-bottom: 0px !important;
    }
    aside{
        margin-bottom: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        flex: 1 !important
    }
    .ant-divider{
        margin: 0 !important;
    }
    .ant-alert{
        padding: 0 !important;
    }
    .sale-infor-side{
        // flex-flow: row !important;
        width: 100% !important;
    }
}