#table-sale-print {
	border-collapse: collapse !important;
    font-family: Tahoma, Geneva, sans-serif !important;
	text-align: left;
}
#table-sale-print td {
	padding: 15px !important;
	text-align: left;
}
#table-sale-print thead td {
	background-color: #54585d !important;
	color: #ffffff !important;
	font-weight: bold !important;
	font-size: 10px !important;
	border: 1px solid #54585d !important;
}
#table-sale-print tbody td {
	color: #636363 !important;
	border: 1px solid #dddfe1 !important;
}
#table-sale-print tbody tr {
	background-color: #f9fafb !important;
}
#table-sale-print tbody tr:nth-child(odd) {
	background-color: #ffffff !important;
}